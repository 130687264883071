import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import CardGrid from "../components/CardGrid";
import TravelAdvisors from "../components/TravelAdvisors";
import TravelDirector from "../components/TravelDirector";
import Testimonials from "../components/Testimonials";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import PlanningTrip from "../components/PlanningTrip";

const OurSpecialities: React.FC = () => {
  return (
    <div className="">
      <Helmet>
        <title>Travel Tips & Inspiration | Travel N Relax Blog</title>
        <meta
          name="title"
          content=" Travel Planning Services | Travel N Relax – Experts in Tailored
          Getaways"
        />
        <meta
          name="description"
          content="Discover 20+ years of expert travel planning with Travel N Relax. From honeymoons to cruises, destination weddings to family vacations, our certified advisors create unforgettable journeys."
        />
        <meta
          name="keywords"
          content="travel planning services, certified travel advisor, destination weddings, honeymoon getaways, family travel, all-inclusive vacations, group travel, river cruise, ocean cruise, Disney vacations, travel n relax, Rob Hale, Lisa Hale"
        />

        <link
          rel="canonical"
          href="https://travelnrelax.com/services/
"
          data-react-helmet="true"
        />
      </Helmet>

      <Navbar subHeading="" />

      <CardGrid />
      <TravelAdvisors />
      <TravelDirector />
      <div className="mt-20">
        <Testimonials />
      </div>
      <PlanningTrip
        headingplanning="Where to next?"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />

      <Footer />
    </div>
  );
};

export default OurSpecialities;
