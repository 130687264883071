import React from "react";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import { fetchData } from "../services/api";
import { Helmet } from "react-helmet";

const AdvisorDetailPage: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 24 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle:
              "About Travel N Relax - Colorado-Based Luxury Travel Agency",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
            metaTitle:
              "Denver-Travel Agency | Customized Vacations & Expert Travel Advisors",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  });
  return (
    <div className="">
      <Helmet>
        <title>{metaData.pageTitle}</title>
        <meta name="tittle" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link
          rel="canonical"
          href="https://travelnrelax.com/about-our-travel-agency/"
        />
      </Helmet>
      <Navbar heading="ABOUT US" subHeading="Empowering traveler legacy." />
      <Aboutus />
      <TravelAdvisors />
      <TravelDirector />
      <OurPhilosophy />
      <PrincipleCard />
      <HeroSection />
      <ServiceOverview />
      <PlanningTrip
        headingplanning="Let us handle the details"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default AdvisorDetailPage;
