import React from "react";
import Leftimage from "../assets/images/Weddingleft.png";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import BannerFamily from "../components/BannerFamily";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import RiverCruisePreperation from "../components/RiverCruisePreperation";
import RiverCruiseTheme from "../components/RiverCruiseTheme";
import RiverBanner from "../components/RiverBanner";
import { fetchData } from "../services/api";
import { Helmet } from "react-helmet";
const RiverCruise: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });
  const [metaData, setMetaData] = useState<{
    metaKeyword: string;
    metaDescription: string;
    metaTitle: string;
  }>({
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });
  
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 9 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

 useEffect(() => {
     const fetchMetaData = async () => {
       try {
         const response = await fetchData<{
           meta_keyword: string;
           meta_description: string;
           meta_title: string;
         }>({
           url: "pages/get",
           data: { pageId: 9 },
         });
 
         if (response?.data) {
           const { meta_keyword, meta_description, meta_title } = response.data;
 
           setMetaData({
             metaKeyword: meta_keyword,
             metaDescription: meta_description,
             metaTitle: meta_title,
           });
         } else {
           throw new Error("Metadata not found for the page.");
         }
       } catch (error) {
         console.error(error);
         setError("Failed to load metadata.");
       }
     };
 
     fetchMetaData();
   }, []);
  return (
    <div>
    <Helmet>
  <title>{metaData.metaTitle}</title>
  <meta name="title" content={metaData.metaTitle} />
  <meta name="keywords" content={metaData.metaKeyword} />
  <meta name="description" content={metaData.metaDescription} />
  <link rel="canonical" href="https://travelnrelax.com/river-cruise/" />
</Helmet>

      <Navbar heading="Best River Cruise Travel Agency in Denver" />
      <RiverCruiseTheme />
      <RiverBanner />
      <RiverCruisePreperation />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}
      <Clients />
      <Footer />
    </div>
  );
};

export default RiverCruise;
