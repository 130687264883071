import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData, ApiResponse } from "../services/api";
import { Helmet } from "react-helmet";
interface ReviewData {
  review_id: string;
  review_title: string;
  subtext: string;
  google_text: string;
  google_link: string;
  yelp_text: string;
  yelp_link: string;
}

const LeaveReview: React.FC = () => {
  const [reviewData, setReviewData] = useState<ReviewData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });

  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        const response: ApiResponse<{ reviews: ReviewData[] }> | null =
          await fetchData<{ reviews: ReviewData[] }>({
            url: "pages/get",
            data: { pageId: 14 },
          });

        if (response?.data?.reviews && response.data.reviews.length > 0) {
          setReviewData(response.data.reviews[0]);
        } else {
          setError("Review data not found.");
        }
      } catch {
        setError("Failed to load review data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviewData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 14 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle: "Denver-Review Travel N Relax | Share Your Experience",
            metaTitle:
              "Leave a Review for Travel N Relax – Denver’s #1 Rated Travel Agency",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.pageTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link rel="canonical" href="https://travelnrelax.com/review/" />
      </Helmet>
      <Header />
      <div className="px-4 md:px-20">
        <h3 className="text-3xl font-akshar font-bold mt-10 sm:mt-20 text-center mb-6 sm:mb-8 uppercase tracking-wide">
          {reviewData?.review_title || "Review Travel N Relax"}
        </h3>
        <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
          {reviewData?.subtext ||
            "We value your feedback and strive to improve your travel experience!"}
        </p>

        <div className="mt-6 md:mt-8 flex flex-col md:flex-row gap-3 justify-center items-center">
          {reviewData?.google_text && reviewData?.google_link && (
            <a
              href={reviewData.google_link}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-auto text-center md:text-left text-black border border-gray-600 py-2 md:py-3 px-4 md:px-4 text-sm md:text-lg font-medium uppercase tracking-wide rounded hover:bg-gray-100"
            >
              {reviewData.google_text}
            </a>
          )}
          {reviewData?.yelp_text && reviewData?.yelp_link && (
            <a
              href={reviewData.yelp_link}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-auto text-center md:text-left text-black border border-gray-600 py-2 md:py-3 px-4 md:px-4 text-sm md:text-lg font-medium uppercase tracking-wide rounded hover:bg-gray-100"
            >
              {reviewData.yelp_text}
            </a>
          )}
        </div>
      </div>

      <PlanningTrip
        headingplanning="Let us handle the details"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default LeaveReview;
