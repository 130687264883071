import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/images/logo.svg";
import { useParams, Link } from "react-router-dom";
import { IoIosGlobe } from "react-icons/io";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaBars,
  FaYoutube,
  FaTimes,
} from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const slugify = (text: string) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");

const HeaderCountry: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [bannerData, setBannerData] = useState<{
    bannerTitle: string;
    bannerImage: string;
  } | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/get",
          { pageId: 17 },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        const allDestinations = response.data?.data?.destinations || [];
        const matched = allDestinations.find(
          (item: any) => slugify(item.name) === id
        );

        if (matched) {
          const countryRes = await axios.post(
            "https://travelnrelax.com/tadmin/api/pages/CountryPageDetails",
            { countryId: matched.id },
            {
              headers: {
                token: STATIC_TOKEN,
                "Content-Type": "application/json",
              },
            }
          );

          if (
            countryRes.data.success &&
            countryRes.data.data.bannerData.length > 0
          ) {
            setBannerData(countryRes.data.data.bannerData[0]);
          }
        }
      } catch (err) {
        console.error("Error fetching banner data:", err);
      }
    };

    if (id) fetchBannerData();
  }, [id]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <nav
      className="w-full top-0 bg-transparent z-10 relative h-screen bg-cover bg-center"
      role="navigation"
      style={{
        backgroundImage: `url(${bannerData?.bannerImage})`,
      }}
    >
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-10 text-white text-center px-4 sm:px-0">
        <h1 className="tracking-wide font-akshar bg-black bg-opacity-20 rounded-md p-5 uppercase text-4xl sm:text-5xl font-bold ">
          {bannerData?.bannerTitle}
        </h1>
      </div>

      {/* Navbar */}
      <div className="relative w-full px-4 md:px-20 z-20">
        <div className="absolute inset-0 bg-black bg-opacity-20 z-[-1]"></div>
        <div className="flex items-center justify-between w-full relative">
          <Link to="/" className="flex-shrink-0">
            <img src={logo} alt="Logo" className="h-16 w-16 md:h-24 md:w-24" />
          </Link>

          <ul className="hidden md:flex items-center tracking-widest space-x-6 lg:space-x-16 text-neutral-100">
            <li>
              <Link
                to="/"
                className="p-2 font-semibold hover:text-black text-neutral-100"
              >
                HOME
              </Link>
            </li>
            <li>
              <Link
                to="/destinations/"
                className="p-2 font-semibold hover:text-black text-neutral-100"
              >
                DESTINATION
              </Link>
            </li>
            <li>
              <Link
                to="/experiences/"
                className="p-2 font-semibold hover:text-black text-neutral-100"
              >
                EXPERIENCES
              </Link>
            </li>
            <li>
              <Link
                to="/go/"
                className="p-2 font-semibold hover:text-black text-neutral-100"
              >
                CONTACT US
              </Link>
            </li>
            <li className="text-white hover:text-black text-sm lg:text-base font-semibold">
              303-317-6945
            </li>
            <button>
              <Link
                to="/go/"
                className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              >
                Ready To Travel?
              </Link>
            </button>
          </ul>

          <button
            onClick={toggleMenu}
            className="md:hidden focus:outline-none text-white"
            aria-label="Toggle menu"
            aria-expanded={isOpen}
          >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-blue-900">
          <button
            className="absolute top-4 right-4 text-white"
            onClick={toggleMenu}
          >
            <FaTimes size={24} />
          </button>
          <ul className="space-y-4 text-lg font-bold text-white text-center">
            <li>
              <Link to="/" onClick={toggleMenu}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="/destinations/" onClick={toggleMenu}>
                DESTINATION
              </Link>
            </li>
            <li>
              <Link to="/experiences/" onClick={toggleMenu}>
                EXPERIENCES
              </Link>
            </li>
            <button>
              <Link
                to="/go/"
                className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              >
                Ready To Travel?
              </Link>
            </button>
          </ul>
        </div>
      )}

      <div className="absolute left-2 bottom-4 flex flex-col items-center gap-4">
        <a
          href="http://facebook.com/TravelnRelax"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaFacebookF size={16} />
        </a>
        <a
          href="https://www.instagram.com/travelnrelax"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaInstagram size={16} />
        </a>
        <a
          href="https://www.linkedin.com/company/travel-n-relax/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <GrLinkedinOption size={16} />
        </a>
        <a
          href="http://twitter.com/travelnrelax"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaTwitter size={16} />
        </a>
        <a
          href="https://www.youtube.com/@travelnrelax621"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaYoutube size={16} />
        </a>
        <a
          href="https://www.yelp.com/biz/travel-n-relax-aurora-2"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 text-sm hover:bg-white hover:text-black transition font-semibold"
        >
          <IoIosGlobe size={16} />
        </a>
      </div>
    </nav>
  );
};

export default HeaderCountry;
