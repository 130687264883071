import React from "react";
import Leftimage from "../assets/images/Weddingleft.png";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import BannerFamily from "../components/BannerFamily";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import RiverCruisePreperation from "../components/RiverCruisePreperation";
import RiverCruiseTheme from "../components/RiverCruiseTheme";
import SkiTheme from "../components/SkiTheme";
import SkiBanner from "../components/SkiBanner";
import SkiVacationPreperation from "../components/SkiVactionPreperation";
import { fetchData } from "../services/api";
import { Helmet } from "react-helmet";

const SkiVacation: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });
const [metaData, setMetaData] = useState<{
    metaKeyword: string;
    metaDescription: string;
    metaTitle: string;
  }>({
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 12 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "pages/get",
          data: { pageId: 12 },
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
          
            metaKeyword: meta_keyword,
            metaDescription: meta_description || "Default Description",
            metaTitle:
              "Denver-Colorado Ski Vacations | Luxury Ski Trips & Packages | Travel N Relax – Book Your Dream Ski Vacation",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);
  return (
    <div>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link
          rel="canonical"
          href="https://travelnrelax.com/colorado-ski-vacations/"
        />
      </Helmet>
      <Navbar heading="Top Destination Wedding Planner in Denver" />
      <SkiTheme />
      <SkiBanner />
      <SkiVacationPreperation />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}
      <Clients />
      <Footer />
    </div>
  );
};

export default SkiVacation;
