import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

interface DestinationDetail {
  destinationId: string;
  countryName: string;
  destinationDescription: string;
  destinationImagesPath: string[];
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const slugify = (text: string) =>
  text.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-+|-+$/g, "");

const BestPlacesToVisitSection: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [destinationDetails, setDestinationDetails] =
    useState<DestinationDetail | null>(null);

  useEffect(() => {
    const fetchDestinationDetails = async () => {
      try {
        const baseUrl = "https://travelnrelax.com/tadmin/api/";

        // Step 1: Get monthId from slug
        const monthsRes = await axios.post(
          `${baseUrl}/pages/get`,
          { pageId: 16 },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        const months = monthsRes.data?.data?.monthlyDestinations || [];
        const matched = months.find((m: any) => slugify(m.title) === slug);

        if (!matched) {
          console.warn("No matched month found for slug:", slug);
          return;
        }

        // Step 2: Fetch destination details using monthId
        const response = await axios.post(
          `${baseUrl}/pages/monthPageDetails`,
          { monthId: matched.monthId },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success && response.data.data.destinationDetails) {
          setDestinationDetails(response.data.data.destinationDetails[0]);
        }
      } catch (err) {
        console.error("Error fetching destination details:", err);
      }
    };

    if (slug) fetchDestinationDetails();
  }, [slug]);

  return (
    destinationDetails && (
      <div className="px-4 md:px-16 lg:px-24 py-8">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8">
          {/* Left Column (Text + Large Image) */}
          <div className="col-span-1 md:col-span-4 flex flex-col gap-y-14">
            <div>
              <h3 className="text-xl md:text-2xl font-bold uppercase">
                {destinationDetails.countryName}
              </h3>
              <div className="text-gray-700 text-sm md:text-base mt-2 h-60 overflow-auto">
                {destinationDetails.destinationDescription}
              </div>
            </div>
            <div>
              <img
                src={destinationDetails.destinationImagesPath?.[1]}
                alt={destinationDetails.countryName}
                className="rounded-lg w-full h-80 object-cover"
              />
            </div>
          </div>

          <div className="col-span-1 md:col-span-4 flex flex-col gap-4">
            <img
              src={destinationDetails.destinationImagesPath?.[3]}
              alt={destinationDetails.countryName}
              className="rounded-lg w-full h-80 object-cover"
            />
            <img
              src={destinationDetails.destinationImagesPath?.[2]}
              alt={destinationDetails.countryName}
              className="rounded-lg w-full h-80 object-cover"
            />
          </div>

          <div className="col-span-1 md:col-span-4">
            <img
              src={destinationDetails.destinationImagesPath?.[0]}
              alt={destinationDetails.countryName}
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default BestPlacesToVisitSection;
