import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import SidebarBlog from "../components/SidebarBlog";
import BlogArticle from "../components/BlogArticle";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData } from "../services/api";

const Blog: React.FC = () => {
  const [metaData, setMetaData] = useState({
    pageTitle: "",
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetchData<{
          meta_keyword: string;
          meta_description: string;
        }>({
          url: "/blogs/list",
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;

          setMetaData({
            pageTitle: "Denver Travel Tips & Guides | Explore Our Latest Blogs",
            metaTitle:
              "Travel N Relax Blog – Travel Tips, Destination Guides & Vacation Advice from Denver Experts",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });
        } else {
          throw new Error("Metadata not found for the page.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load metadata.");
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.pageTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta
          name="keywords"
          content={metaData.metaKeyword || "Default Keyword"}
        />
        <meta
          name="description"
          content={metaData.metaDescription || "Default Description"}
        />
        <link rel="canonical" href="https://travelnrelax.com/blog/" />
      </Helmet>
      <Navbar />
      <BlogArticle />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default Blog;
