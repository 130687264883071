import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import MonthlyDestinationCard from "./MonthlyDestinationCard"; // Card Component

interface Destination {
  destinationId: string;
  bannerTitle: string;
  countryName: string;
  destinationDescription: string;
  bannerImage: string;
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const slugify = (text: string) =>
  text.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-+|-+$/g, "");

const MonthlyDestinationList: React.FC = () => {
  const { slug } = useParams<{ slug: string }>(); // ✅ renamed for clarity
  const [destinations, setDestinations] = useState<Destination[]>([]);

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const monthsResponse = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/get",
          { pageId: 16 },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        const months = monthsResponse.data?.data?.monthlyDestinations || [];
        const matchedMonth = months.find(
          (m: any) => slugify(m.title) === slug
        );

        if (!matchedMonth) {
          console.warn("No month matched for slug:", slug);
          return;
        }

        const detailsResponse = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/monthPageDetails",
          { monthId: matchedMonth.monthId },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );

        if (
          detailsResponse.data.success &&
          detailsResponse.data.data.viewMoreDestinations
        ) {
          setDestinations(detailsResponse.data.data.viewMoreDestinations);
        }
      } catch (err) {
        console.error("Error fetching monthly destinations:", err);
      }
    };

    if (slug) fetchDestinations();
  }, [slug]);

  if (destinations.length === 0) return null;

  return (
    <div className="px-4 md:px-16 lg:px-24 py-8">
      <h2 className="text-center text-2xl md:text-3xl font-bold tracking-wide mb-6 uppercase">
        More Destinations
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 justify-items-center w-full max-w-6xl mx-auto">
        {destinations.map((destination) => (
          <MonthlyDestinationCard
            key={destination.destinationId}
            title={destination.bannerTitle || destination.countryName}
            description={destination.destinationDescription}
            imageUrl={destination.bannerImage}
            destinationId={destination.destinationId}
          />
        ))}
      </div>
    </div>
  );
};

export default MonthlyDestinationList;
