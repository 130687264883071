import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/images/logo.svg";
import { useParams, Link } from "react-router-dom";
import { IoIosGlobe } from "react-icons/io";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaBars,
  FaTimes,
  FaYoutube,
} from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const slugify = (text: string) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");

const HeaderMonthly: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [banner, setBannerData] = useState<{
    bannerText: string;
    image: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const allMonthsRes = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/get",
          { pageId: 16 },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );
  
        console.log("Months data:", allMonthsRes.data);
  
        const months = allMonthsRes.data?.data?.monthlyDestinations || [];
        const matchedMonth = months.find((m: any) => slugify(m.title) === slug);
  
        console.log("Matched month:", matchedMonth);
  
        if (!matchedMonth) {
          console.warn("No matching month found for slug:", slug);
          return;
        }
  
        const detailRes = await axios.post(
          "https://travelnrelax.com/tadmin/api/pages/monthPageDetails",
          { monthId: matchedMonth.monthId },
          {
            headers: {
              token: STATIC_TOKEN,
              "Content-Type": "application/json",
            },
          }
        );
  
        console.log("Detail response:", detailRes.data);
  
        if (detailRes.data.success && detailRes.data.data.banner.length > 0) {
          setBannerData(detailRes.data.data.banner[0]);
        } else {
          throw new Error("No banner data available.");
        }
      } catch (err) {
        console.error("Error fetching banner data:", err);
        setError("Failed to fetch banner data.");
      }
    };
  
    if (slug) {
      fetchBannerData();
    }
  }, [slug]);
  

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <nav
      className="w-full top-0 bg-transparent z-10 relative h-screen bg-cover bg-center"
      style={{
        backgroundImage: `url(${banner?.image})`,
      }}
    >
      {/* Banner Title */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-10 text-white text-center px-4 sm:px-0">
        <h1 className="tracking-wide p-5 bg-black bg-opacity-20 font-akshar rounded-md uppercase text-4xl sm:text-6xl font-bold">
          {banner?.bannerText}
        </h1>
      </div>

      {/* Navigation */}
        <div className="relative w-full px-4 md:px-20 z-20">
          <div className="absolute inset-0 bg-black bg-opacity-20 z-[-1]"></div>
          <div className="flex items-center justify-between w-full relative">
            <Link to="/" className="flex-shrink-0">
              <img src={logo} alt="Logo" className="h-16 w-16 md:h-24 md:w-24" />
            </Link>

            <ul className="hidden md:flex items-center tracking-widest space-x-6 lg:space-x-16 text-neutral-100">
              <li>
                <Link to="/" className="p-2 font-semibold hover:text-black">
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  to="/destinations/"
                  className="p-2 font-semibold hover:text-black"
                >
                  DESTINATION
                </Link>
              </li>
              <li>
                <Link
                  to="/experiences/"
                  className="p-2 font-semibold hover:text-black"
                >
                  EXPERIENCES
                </Link>
              </li>
              <li>
                <Link to="/go/" className="p-2 font-semibold hover:text-black">
                  CONTACT US
                </Link>
              </li>
              <li className="text-white text-sm lg:text-base font-semibold">
                303-317-6945
              </li>
              <button>
                <Link
                  to="/go/"
                  className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
                >
                  Ready To Travel?
                </Link>
              </button>
            </ul>

            <button
              onClick={toggleMenu}
              className="md:hidden focus:outline-none text-white"
              aria-label="Toggle menu"
              aria-expanded={isOpen}
            >
              {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-gray-900 bg-opacity-95">
          <button
            className="absolute top-4 right-4 text-white"
            onClick={toggleMenu}
            aria-label="Close menu"
          >
            <FaTimes size={24} />
          </button>
          <ul className="space-y-6 text-lg font-bold text-white text-center">
            <li>
              <Link to="/" onClick={toggleMenu}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="/destinations/" onClick={toggleMenu}>
                DESTINATION
              </Link>
            </li>
            <li>
              <Link to="/experiences/" onClick={toggleMenu}>
                EXPERIENCES
              </Link>
            </li>
            <button className="mt-12">
              <Link
                to="/go/"
                onClick={toggleMenu}
                className="w-full py-3 px-3 bg-blue-900 text-white text-xl rounded-lg"
              >
                Ready To Travel?
              </Link>
            </button>
          </ul>
        </div>
      )}

      {/* Social Icons */}
      <div className="absolute left-2 bottom-4 flex flex-col items-center gap-4 z-30">
        
        <a
          href="http://facebook.com/TravelnRelax"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaFacebookF size={16} />
        </a>
        <a
          href="https://www.instagram.com/travelnrelax"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaInstagram size={16} />
        </a>
        <a
          href="https://www.linkedin.com/company/travel-n-relax/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <GrLinkedinOption size={16} />
        </a>
        <a
          href="http://twitter.com/travelnrelax"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaTwitter size={16} />
        </a>
        <a
          href="https://www.youtube.com/@travelnrelax621"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 hover:bg-white hover:text-black transition"
        >
          <FaYoutube size={16} />
        </a>
        <a
          href="https://www.yelp.com/biz/travel-n-relax-aurora-2"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white border border-white rounded-full p-2 text-sm hover:bg-white hover:text-black transition font-semibold"
        >
          <IoIosGlobe size={16} />
        </a>
      </div>
    </nav>
  );
};

export default HeaderMonthly;
