import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import TestimonialView from "../components/TestimonialView";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { fetchData } from "../services/api";

const TestimonialsCards: React.FC = () => {
  const [metaData, setMetaData] = useState({
    
    metaKeyword: "",
    metaDescription: "",
    pageTitle: "",
    metaTitle:""
  });

  // Fetch testimonial data
  useEffect(() => {
    const fetchTestimonialsData = async () => {
      try {
        const response = await fetchData<{ meta_keyword: string; meta_description: string }>({
          url: "/testimonials/testimonial_list", 
        });

        if (response?.data) {
          const { meta_keyword, meta_description } = response.data;
          setMetaData({
            pageTitle: "Client Testimonials | Travel N Relax - Trusted Travel Agency in Denver",
            metaTitle: "Denver Travel Testimonials | Travel N Relax | Client Experiences & Reviews",
            metaKeyword: meta_keyword || "Default Keyword",
            metaDescription: meta_description || "Default Description",
          });
          
        }
      } catch (error) {
        console.error("Failed to load testimonial data:", error);
      }
    };

    fetchTestimonialsData();
  }, []);

  return (
    <div>
      <Helmet>
      <title>{metaData.pageTitle}</title>
      <meta name="tittle" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link rel="canonical" href="https://travelnrelax.com/testimonials/" />
      </Helmet>
      <Header />
      <TestimonialView />
      <PlanningTrip
        headingplanning="Where to next?"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default TestimonialsCards;
