import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import Carousels from "../components/Carousels";
import CardGrid from "../components/CardGrid";
import Packages from "../components/Packages";
import About from "../components/About";
import TravelAdvisors from "../components/TravelAdvisors";
import TravelDirector from "../components/TravelDirector";
import SeamlessTravel from "../components/SeamlessTravel";
import { fetchData } from "../services/api";
import BlogSection from "../components/BlogSection";
import Testimonials from "../components/Testimonials";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import axios from "axios";


interface MetaData {
  metaTitle: string;
  metaKeyword: string;
  metaDescription: string;
}

const Home: React.FC = () => {
  const [metaData, setMetaData] = useState<MetaData>({
    metaTitle: "",
    metaKeyword: "",
    metaDescription: "",
  });

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const res = await axios.post("https://travelnrelax.com/tadmin/api/pages/home", {
          pageId: 1, // or whichever page you're targeting
        });

        const { meta_title, meta_keyword, meta_description } = res.data?.data || {};

        setMetaData({
          metaTitle: meta_title || "Travel N Relax",
          metaKeyword: meta_keyword || "Travel Agency, Denver, Vacation",
          metaDescription: meta_description || "Plan your next adventure with Travel N Relax.",
        });
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    };

    fetchMetaData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaData.metaTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta
          name="keywords"
          content={metaData.metaKeyword || "Default Keyword"}
        />
        <meta
          name="description"
          content={metaData.metaDescription || "Default Description"}
        />
        <link rel="canonical" href="https://travelnrelax.com/" />
      </Helmet>

      <Navbar
        heading="20 YEARS OF MAKING TRAVEL DREAMS COME TRUE"
        subHeading="UNIFYING THE WORLD, ONE TRAVEL AT A TIME"
      />

      <div className="relative z-10">
        <Aboutus />
      </div>
      <div className="relative z-10">
        <Carousels />
      </div>

      <div className="relative z-10 bg-[#F4F4F4]">
        <CardGrid />
      </div>
      <div className="relative z-10">
        <Packages />
      </div>
      <div className="z-10 w-100">
        <About />
      </div>
      <div className="z-10">
        <TravelAdvisors />
      </div>
      <div className="relative z-10">
        <TravelDirector />
      </div>
      <div className="relative z-10 mt-20">
        <SeamlessTravel />
      </div>
      <div className="relative z-10">
        <BlogSection />
      </div>
      <div className="relative z-10">
        <Testimonials />
      </div>
      <PlanningTrip
        headingplanning="Where to next?"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <div className="relative z-10">
        <Clients />
      </div>
      <div className="relative z-10">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
