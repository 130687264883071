import React from "react";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import LisaDescription from "../components/LisaDescription";
import DirectorCertification from "../components/DirectorCertification";
import BookingIframe from "../components/BookingFrame";
import DirectorTestimonial from "../components/DirectorTestimonial";

const DirectorDetail: React.FC = () => {
  return (
    <div className="">
      <Helmet>
        <title>
          Meet Lisa Hale – Director of Celebrations & ASTA Verified Travel
          Advisor
        </title>
        <meta
          name="tittle"
          content="Lisa Hale | Director of Celebrations & ASTA Verified Travel Advisor | Destination Weddings & Honeymoons Specialist
"
        />
        <meta
          name="description"
          content="Lisa Hale is an ASTA Verified Travel Advisor and Director of Celebrations, specializing in destination weddings, honeymoons, cruises, and inclusive travel. Let Lisa make your travel dreams and celebrations unforgettable!
"
        />
        <meta
          name="keywords"
          content="Lisa Hale, ASTA Verified Travel Advisor, Certified Travel Associate, Destination Weddings Specialist, Honeymoon travel planner, Disney Weddings expert, Certified Autism Travel Professional, Puerto Rico Destination Specialist, Hawaii travel specialist, Alaska cruise tours, Royal Caribbean cruises, Sandals resort weddings, romantic vacations, luxury travel, inclusive travel advisor
"
        />

        <link
          rel="canonical"
          href="https://travelnrelax.com/about-our-travel-agency/lisa-hale/"
        />
      </Helmet>
      <Navbar />
      <LisaDescription />
      <DirectorCertification />
      <BookingIframe />
      <DirectorTestimonial />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default DirectorDetail;
