import React, { useEffect, useState } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface Speciality {
  id: string;
  name: string;
  url: string;
}

const PromoBanner: React.FC = () => {
  const [specialities, setSpecialities] = useState<Speciality[]>([]);

  useEffect(() => {
    const fetchSpecialities = async () => {
      const response: ApiResponse<Speciality[]> | null = await fetchData({
        url: "/pages/specialities_list",
      });

      if (response?.success && Array.isArray(response.data)) {
        setSpecialities(response.data);
      }
    };

    fetchSpecialities();
  }, []);

  return (
    <div className="p-4 flex flex-col items-center justify-center bg-white text-black">
      <h2 className="mt-3 sm:mt-5 uppercase text-lg sm:text-2xl lg:text-3xl font-semibold text-center font-akshar">
        Our Specialities
      </h2>

      {specialities.length > 0 && (
        <ul className="mt-5 flex flex-wrap justify-center gap-2 sm:gap-4 text-center w-full max-w-lg">
          {specialities.map((speciality) => (
            <li
              key={speciality.id}
              className="text-gray-700 text-sm sm:text-base font-poppins text-center bg-gray-100 px-3 py-2 rounded-lg shadow-sm hover:bg-gray-200 transition-all"
            >
              <a
                href={speciality.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
              >
                {speciality.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PromoBanner;