import React from "react";
import Navbar from "../components/Navbar";
import HoneyMoonTheme from "../components/HoneyMoonTheme";
import BannerFamily from "../components/BannerFamily";
import HoneyMoonPreperation from "../components/HoneyMoonPreperation";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import BannerHoneyMoon from "../components/BannerHoneyMoon";
import { fetchData } from "../services/api";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const HoneyMoon: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });
  const [metaData, setMetaData] = useState({
    metaKeyword: "",
    metaDescription: "",
    metaTitle: "",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 5 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);

 useEffect(() => {
     const fetchMetaData = async () => {
       try {
         const response = await fetchData<{
           meta_keyword: string;
           meta_description: string;
           meta_title: string;
         }>({
           url: "pages/get",
           data: { pageId: 5 },
         });
 
         if (response?.data) {
           const { meta_keyword, meta_description, meta_title } = response.data;
 
           setMetaData({
             metaKeyword: meta_keyword,
             metaDescription: meta_description,
             metaTitle: meta_title,
           });
         } else {
           throw new Error("Metadata not found for the page.");
         }
       } catch (error) {
         console.error(error);
         setError("Failed to load metadata.");
       }
     };
 
     fetchMetaData();
   }, []);
  return (
    <div>
      <Helmet>
      <title>{metaData.metaTitle}</title>
        <meta name="title" content={metaData.metaTitle} />
        <meta name="keywords" content={metaData.metaKeyword} />
        <meta name="description" content={metaData.metaDescription} />
        <link rel="canonical" href="https://travelnrelax.com/honeymoon/" />
      </Helmet>
      <Navbar heading="Denver’s Best Honeymoon Travel Agent" />
      <HoneyMoonTheme />
      <BannerHoneyMoon />
      <HoneyMoonPreperation />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}
      <Clients />
      <Footer />
    </div>
  );
};

export default HoneyMoon;
